import React from 'react'

const HomepageNav = () => (
  <>
    <nav className="navbar home">
      <div className="wrapper">
        <div></div>
        <div className="nav-links">
          <a href="/about">About</a>
          <a href="/citations">Citations</a>
          <a href="/contact">Contact</a>
        </div>
      </div>
    </nav>
    <div className="line"></div>
  </>
)

export default HomepageNav
